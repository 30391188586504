<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-wrap mb-2 w-full lg:w-1/2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="w-full lg:w-1/2 pb-4">
      <form @submit.prevent="update(teacher)" @keydown="removeError($event.target.id)">
        <h3 class="text-2xl my-4">
          Actualizar {{ isCoordinator ? 'coordinador' : 'profesor' }}
        </h3>
        <div class="flex flex-col space-y-4">

          <a-photo-selector v-model="teacher.profile_picture" :loading="loading" />

          <h4 class="text-xl font-bold text-gray-800 mb-4">
            Información básica
          </h4>

          <a-input
            label="Nombres"
            id="first_name"
            required
            class="my-4"
            placeholder="Nombres"
            :error="error('first_name', { attribute: 'nombres' })"
            v-model="teacher.first_name"/>

          <a-input
            label="Apellidos"
            class="mb-4"
            required
            id="last_name"
            placeholder="Apellidos"
            :error="error('last_name', { attribute: 'apellidos' })"
            v-model="teacher.last_name"/>

          <a-input
            label="Profesión"
            class="mb-4"
            required
            id="profession"
            placeholder="Profesión"
            :error="error('profession', { attribute: 'profesión' })"
            v-model="teacher.profession"/>

          <a-input
                label="Cédula de identidad"
                required
                class="mb-4"
                id="id_document"
                placeholder="Cédula de identidad"
                :error="error('id_document', { attribute: 'cédula de identidad' })"
                v-model="teacher.id_document"/>
          <h4 class="text-xl font-bold text-gray-800 mb-4 pt-4">
            Información de contacto
          </h4>

          <a-input
            required
            label="Teléfono"
            class="mb-4"
            id="phone"
            placeholder="Teléfono de habitación"
            :error="error('phone', { attribute: 'teléfono de habitación' })"
            v-model="teacher.phone"/>

          <a-input
            id="mobile_phone"
            class="mb-4"
            required
            label="Teléfono celular"
            placeholder="Teléfono celular"
            :error="error('mobile_phone', { attribute: 'teléfono celular' })"
            v-model="teacher.mobile_phone"/>

          <a-input
            id="address"
            class="mb-4"
            required
            label="Dirección"
            placeholder="Dirección"
            :error="error('address', { attribute: 'dirección' })"
            v-model="teacher.address"/>

          <label class="mb-2 text-gray-700 font-medium block">¿Esta activo?</label>
          <a-switch v-model="teacher.enabled" class="mb-4" />


          <label class="mb-2 text-gray-700 font-medium block">¿Es coordinador?</label>
          <a-switch v-model="teacher.is_coordinator" class="mb-4" />

          <!-- <template v-if="teacher.is_coordinator">
                <a-select
                  label="Especialidad"
                  required
                  @input="removeError('specialization_id')"
                  :loading="loadingSpecialization"
                  :source="specializations.map(el => ({ label: el.title, value: el.id }))"
                  :error="error('specialization_id', { attribute: 'especialidad' })"
                  v-model="teacher.specialization_id"/>
              </template> -->
        </div>

        <div class="flex space-x-2 mt-4">
          <a-button class="mr-2" type="submit" mode="primary" :loading="loading">
            Actualizar cambios
          </a-button>

          <a-button outlined :to="{ name: 'teachers.index' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>

    <a-modal
      title="Un momento!!"
      hide-top-cancel
      cancel-text="Ir al listado de coordinadores"
      @cancel="$router.push({ path: '/coordinators' })"
      :show="isCoordinator && !isTeacherCoordinator">
      <div class="flex flex-col space-y-4 items-center">
        <div class="rounded-full bg-gradient-to-br from-blue-400 to-blue-700 items-center justify-center w-32 h-32 flex  text-3xl text-white shadow-md" v-if="!teacher.profile_picture">
          {{ `${teacher.first_name} ${teacher.last_name}` | initials }}
        </div>
        <img v-else :src="teacher.profile_picture" class="rounded-full w-32 h-32 shadow-md">
        <h1 class="text-xl">
          {{ teacher.first_name }} {{ teacher.last_name }} <b>NO</b> es un coordinador
        </h1>
      </div>
    </a-modal>

    <a-modal
      title="Un momento!!"
      hide-top-cancel
      cancel-text="Ir al listado de profesores"
      @cancel="$router.push({ name: 'teachers.index' })"
      :show="error_code === 403">
      <div class="flex flex-col space-y-4 items-center">
        <h1 class="text-xl">
          No tienes autorización para acceder a la información de este profesor
        </h1>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
export default {
    props: {
        isCoordinator: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        picture: null,
        isUpdating: false,
        teacher: {
            is_coordinator: 1,
            enabled: true,
            courses: []
        },
        error_code: null,
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            teachers: state => state.teachers.pagination.data,
            currentTeacherOnState: ({ teachers }) => teachers.current,
            loading: state => state.teachers.loading,
            courses: state => state.courses.all,
            loadingCourses: state => state.courses.loading,
            loadingSpecialization: state => state.specializations.loading,
            specializations: state => state.specializations.all,
        }),
        isTeacherCoordinator() {
            if (this.currentTeacherOnState === null) {
                if (typeof this.currentTeacherOnState === 'object' && Object.keys(this.currentTeacherOnState).length === 0) {
                    return true
                }
            }

            if (this.currentTeacherOnState && Object.keys(this.currentTeacherOnState).length > 0) {
                return this.currentTeacherOnState.is_coordinator
            }

            return false
        }
    },
    methods: {
        ...mapMutations({
            setTeacher: 'teachers/SET_TEACHER'
        }),
        ...mapActions({
            removeError: 'removeError',
            fetchTeacher: 'teachers/show',
            update: 'teachers/update',
            updateProfilePicture: 'teachers/updateProfilePicture',
            fetchCourses: 'courses/index',
            fetchSpecializations: 'specializations/fetchSpecializations',
        }),
        // handlePhotoUpdate() {
        //     if (this.picture) {
        //         this.updateProfilePicture(this.teacher.profile_picture)
        //     }
        // }
    },
    watch: {
        currentTeacherOnState(val) {
            if (val) {
                delete val.email
                this.teacher = {
                    ...val,
                    courses: (val.courses ?? []).map(el => el.id)
                }
            }
        }
    },
    mounted() {
        const { id } = this.$route.params
        this.fetchCourses({ limit: 0 })
        this.fetchSpecializations({ limit: 0 })
        this.fetchTeacher({ id })
            .catch(err => this.error_code = err.response?.status)
    }
}
</script>
